.pagination-list {
  display: flex;
  list-style: none;
  margin-top: 4rem;
}

.pagination-list li {
  margin-right: 1rem;
  font-size: 1.8rem;
  font-weight: bold;
}

.pagination-list li a {
  text-decoration: none;
  color: white;
}

.pagination-list li.active a {
  color: #c93450;
}
