.mybar-ad--container {
  height: 60%;
  max-width: 30rem;
  display: flex;
  justify-content: center;
}

.mybar-ad--container img {
  height: 100%;
  max-width: 100%;
}
