.allrecipes--container {
  background-color: #61576b;
  width: calc(100vw - 15rem);
  height: 100%;
  /* overflow: scroll;
  overflow-x: hidden; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allrecipes--header {
  height: 10%;
  width: 100%;
  margin-bottom: 2rem;
}

.allrecipes--heading {
  font-size: 4rem;
  margin: 2rem;
}

.allrecipes--content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allrecipes-display {
  display: flex;
  justify-content: center;
}

.allrecipes--search {
  display: flex;
  flex-direction: column;
  width: 30rem;
}

.allrecipes--search input {
  height: 3.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: none;
}

.allrecipes--search select {
  height: 3.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: none;
}

.allrecipes-display {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media all and (max-width: 900px) {
  .allrecipes--container {
    width: 100vw;
  }
}
