.favorites--container {
  background-color: #61576b;
  width: calc(100vw - 15rem);
  height: 100%;
  min-height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.favorites--header {
  height: 10%;
  width: 100%;
  margin-bottom: 2rem;
}

.favorites--heading {
  font-size: 4rem;
  margin: 2rem;
}

@media all and (max-width: 900px) {
  .favorites--container {
    width: 100vw;
  }
}
