.footer--container {
  height: 10rem;
  width: 100%;
  background-color: #61576b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-links {
  display: flex;
  list-style: none;
}

.footer-link--item a {
  text-decoration: none;
  color: #ebe6f0;
  font-size: 1.3rem;
  margin-right: 0.8rem;
  border-right: solid 2px #ebe6f0;
  padding-right: 0.8rem;
}

.footer-link--item:last-of-type a {
  border: none;
}

.footer-icons {
  font-size: 2.5rem;
  color: #ebe6f0;
  display: flex;
}

.mobile-icons {
  margin-right: 1.4rem;
}
.mobile-icons svg {
  cursor: pointer;
}

.social-icons svg {
  cursor: pointer;
}

@media all and (max-width: 600px) {
  .footer--container {
    flex-direction: column;
  }

  .footer-link--item a {
    font-size: 1.1rem;
  }

  .footer-icons {
    margin-top: 2rem;
  }
}
