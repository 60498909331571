.heart {
  width: 50px;
  height: 50px;
  background: url("https://cssanimation.rocks/images/posts/steps/heart.png")
    no-repeat;
  background-position: 0 0;
  background-size: 1450px;
  cursor: pointer;
  transition: background-position 1s steps(28);
  transition-duration: 0s;
}

.heart.is-active {
  transition-duration: 1s;
  background-position: -1400px 0;
}

.heart.is-favorited {
  transition-duration: 0s;
  background-position: -1400px 0;
}

/* .stage {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
