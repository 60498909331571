.recipe-placeholder--container {
  width: 30rem;
  height: 35rem;
  background-color: aliceblue;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: inset 0px -5px 0px 0px #cbcbcb;
  margin: 1rem;
}

.placeholder-img--container {
  width: 100%;
  height: 83%;
  background-color: lightgrey;
}

.recipe-placeholder--content {
  width: 100%;
  height: 17%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 1rem;
}

.recipe-placeholder--title {
  height: 2rem;
  width: 70%;
  background-color: lightgray;
  border-radius: 10px;
}

.heart-container svg {
  color: lightgray;
  font-size: 2.7rem;
}
