.mybar-form--container {
  width: 80%;
  height: 18rem;
  background-color: #7e718c;
  border-radius: 1rem;
  max-width: 40rem;
}

.mybar-form {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.mybar-form input {
  height: 3.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: none;
}

.ingredients-select {
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

.mybar-form button {
  height: 3.5rem;
  border-radius: 5px;
  border: none;
  transition: all 0.2s ease-in-out;
  font-size: 2rem;
}

.mybar-form button:hover {
  background-color: #bfb7c7;
  cursor: pointer;
}
