.sidebar--container {
  height: 100%;
  width: 15rem;
  display: flex;
  flex-direction: column;
  background-color: #48404f;
  color: #ebe6f0;
  position: fixed;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

.sidebar--container li {
  font-size: 1.6rem;
  display: flex;
}

.sidebar--container li:hover {
  background-color: #2f2a34;
  cursor: pointer;
}

.sidebar--container li a {
  text-decoration: none;
  color: #ebe6f0;
  height: 100%;
  width: 100%;
  display: inline-block;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
}

.list-span {
  margin-left: 1rem;
}

.sidebar-icons {
  color: white;
  width: 3rem;
}

.sidebar--container li a svg {
  width: 3rem;
}

/* Hamburger CSS */
.hamburger-btn {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 3.2rem;
  width: 3.5rem;
  cursor: pointer;
  position: fixed;
  top: 1.8rem;
  right: 2rem;
  transition: all 0.4s ease-in-out;
  user-select: none;
}

.hamburger-line {
  width: 3.5rem;
  height: 0.3rem;
  background-color: white;
  position: absolute;
  transition: all 0.4s ease-in-out;
}

.hamburger-mid {
  top: 1.3rem;
}

.hamburger-bot {
  top: 2.6rem;
}

.hamburger-top--active {
  transform: rotate(135deg);
  top: 1rem;
}

.hamburger-mid--active {
  opacity: 0;
}

.hamburger-bot--active {
  transform: rotate(-135deg);
  top: 1rem;
}

/* Media Queries */

@media all and (max-width: 900px) {
  .sidebar--container {
    transform: translateX(-15rem);
  }
  .sidebar--active {
    transform: translateX(0);
  }

  .hamburger-btn {
    display: flex;
  }
}
