@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem = 10px */
  font-family: "Varela Round", sans-serif;
  background-color: #61576b;
}

.App {
  display: flex;
}

.app--main-content {
  overflow: auto;
  height: 100%;
  padding-left: 15rem;
}

/* .app-sidebar--container {
  position: relative;
} */

@media all and (max-width: 900px) {
  .app--main-content {
    padding-left: 0;
  }
}
