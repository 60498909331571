.mybar--container {
  background-color: #61576b;
  width: calc(100vw - 15rem);
  height: 100vh;
  display: flex;
  justify-content: center;
}

.mybar--margin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.mybar--header {
  height: 10%;
  width: 100%;
  margin-bottom: 2rem;
}

.mybar--heading {
  font-size: 4rem;
  margin: 2rem;
}

.mybar--display {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90%;
}

.mybar--display-left {
  max-height: 100%;
  width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mybar--display-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40rem;
}

@media all and (max-width: 900px) {
  .mybar--container {
    width: 100vw;
  }
}

@media all and (max-width: 600px) {
  .mybar--display {
    flex-direction: column;
  }

  .mybar--container {
    height: min-content;
    justify-content: center;
  }
}
