.mybar-list--container {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 40rem;
  max-height: 80%;
  flex-grow: 3;
  background-color: #7e718c;
  margin: 2rem 0;
  border-radius: 10px;
  padding: 2rem;
  overflow: scroll;
}
