.list-item-content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
}

.list-item--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.mybar-list--item {
  margin-bottom: 2rem;
  background-color: #5d5367;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0.3rem 0 0 #c7c6c5, 0 0 0 0.1rem rgba(49, 46, 43, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mybar-list--item p {
  font-size: 1.5rem;
}

.open-accordian-btn {
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.control-btns {
  display: flex;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.control-btns.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.control-btns button {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name-par {
  color: white;
}

.brand-par {
  color: darkgray;
}
