.mybar-instructions--container {
  width: 30rem;
  height: 22rem;
  list-style: none;
  margin-top: 2rem;
  background-color: #7f718e;
  border-radius: 10px;
  padding: 2rem;
  overflow: auto;
}
.mybar-instructions--title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.mybar-instructions--item {
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  background-color: #5d5367;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0.3rem 0 0 #c7c6c5, 0 0 0 0.1rem rgba(49, 46, 43, 0.06);
  color: white;
  margin-bottom: 2rem;
}
.mybar-instructions--item svg {
  font-size: 1.8rem;
  flex-shrink: 0;
  margin-right: 1rem;
}
