.recipe-thumb--container {
  max-width: 30rem;
  background-color: aliceblue;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: inset 0px -5px 0px 0px #cbcbcb;
  margin: 1rem;
}

.thumbnail--container {
  width: 100%;
}

.thumbnail--container img {
  width: 100%;
}

.recipe-thumb--title {
  width: max-content;
  text-align: center;
  font-size: 140%;
  font-weight: bold;
  color: #353338;
}

.recipe-thumb--content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 1rem;
}
